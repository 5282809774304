.message_input {
  height: 50px;
  align-items: left;
  display: flex;
  flex-direction: row;
}

.message_input__input {
  height: 30px;
  font-size: 14px;
  width: 92%;
  /* margin-top: 8px; */
  margin-left: 6px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 6px;
}

.message_input__message_bar {
  display: flex;
  flex-direction: row;
  width: 99%;
  margin-top: 6px;
  margin-left: 6px;
  color: blue;
}

.message_input__send:hover {
  color: red;
}

.message_input__send:active {
  color: pink;
}
