.offer_accepted_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 460px;
  justify-content: center;
  background-color: lightblue;
  border-radius: 10px;
  padding: 0px 10px 10px 10px;
}

.offer_accepted_box__title {
  color: rgb(63, 62, 62);
  margin-top: 12px;
  margin-bottom: 4px;
  font-size: 22px;
}

.offer_accepted_box__subtitle {
  font-size: 12px;
}

.offer_accepted_box__amount {
  font-size: 42px;
  font-style: bold;
  color: blue;
}

.offer_accepted_box__text {
  color: grey;
  font-size: 14px;
}

.offer_accepted_box__button {
  background-color: rgb(188, 32, 40);
  color: white;
  border-radius: 20px;
  width: 160px;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  height: 40px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.offer_accepted_box__button:hover {
  background-color: rgb(255, 0, 0);
  cursor: pointer;
}

.offer_accepted_box__button:active {
  background-color: pink;
  box-shadow: none;
}
