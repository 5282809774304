.acct_part_1 {
  /* color: green; */
  width: 600px;
}

.acct_part_1__input_box {
  padding: 20px 20px 0px 10px;
  border-color: #ccc;
}

.acct_part_1__input_label {
  padding-bottom: 10px;
}

.acct_part_1__locator:hover {
  cursor: pointer;
  color: grey;
}

.acct_part_1__dob {
  display: flex;
  flex-direction: row;
  padding: 0px 20px 20px 14px;
}

.acct_part_1__dd_month {
  width: 200px;
  z-index: 2001;
  background: white;
}

.acct_part_1__dd_day {
  width: 200px;
}

.acct_part_1__dd_year {
  width: 200px;
}

.acct_part_1__continue_button {
  background-color: blue;
  color: white;
  border-radius: 20px;
  width: 140px;
  height: 40px;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}

.modal {
  /* height: 200px; */
}
