.messages_list_item {
  height: 80px;
  border: 1px solid #ccc;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
}

.messages_list_item__info {
  display: flex;
  flex-direction: column;
  text-align: left;
  color: rgba(0, 0, 0, 0.65);
}

.messages_list_item__last_msg_date {
  text-align: right;
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
}

.messages_list_item__text_wrapper {
  display: flex;
  flex-direction: column;
  width: 300px;
  padding-top: 6px;
}
