.offer_button__wrapper {
  margin: 0px 10px 0px 10px;
}

.offer_button__icon {
  border-radius: 30px;
  border-color: red;
  border-width: 1px;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.2);
  width: 50px;
  height: 40px;
  vertical-align: middle;
  padding-top: 6px;
}

.offer_button__offer_box {
  /* border: 1px solid black; */
  border-radius: 4px;
  padding: 0px 8px 0px 8px;
  box-shadow: 2px 2px 2px #bebfc0;
}

.offer_button__offer_amount {
  font-size: 32px;
  color: blue;
  margin-top: -4px;
}

.offer_button__offer_label {
  font-size: 14px;
  margin-top: -6px;
}

.offer_button__icon:hover {
  color: red;
}

.offer_button__icon:active {
  color: pink;
  box-shadow: none;
}

.offer_button__label {
  font-size: 14px;
}
