.card {
  background-color: #fff;
  border: 2px solid black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 220px;
  border-radius: 10px;
  margin: 10px;
}

.card .title {
  background-color: #f2f2f2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px 10px 0px 10px;
}

.username {
  color: white;
  margin-top: 270px;
  text-align: left;
  margin-bottom: 0px;
  font-size: 1rem;
}

.age {
  font-size: 1rem;
  color: white;
  text-align: left;
}

.grad_black {
  padding: 10px 10px 10px 10px;
  background: linear-gradient(to bottom, transparent 70%, black 100%);
}

.card__buttons_wrapper {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 6px 10px 6px 10px;
}
