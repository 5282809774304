.prefs__wrapper {
  display: flex;
  flex-direction: column;
}

.prefs__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 16px;
}

.prefs__section {
  font-size: 20px;
  text-align: left;
  padding: 10px;
}

.prefs__title {
  font-size: 24px;
  padding: 10px;
}

.prefs__label {
  font-size: 16px;
  padding-top: 2px;
}

.prefs__label_lower {
  font-size: 16px;
  padding-top: 8px;
}

.prefs__arrow {
  padding: 10px 0px 0px 10px;
  align-items: left;
  display: flex;
}
