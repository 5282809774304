.gallery {
  border: 1px solid #ccc;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.gallery__photo_grid {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-top: 16px;
}

.gallery__image {
  width: 160px;
  height: 200px;
  border-radius: 10px;
  margin: 4px;
}

.gallery__title {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  margin-left: 20px;
  margin-top: 20px;
}
