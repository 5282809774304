.offer_pending_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 460px;
  justify-content: center;
  background-color: rgba(255, 175, 0, 0.25);
  border-radius: 10px;
  padding: 0px 10px 10px 10px;
}

.offer_pending_box__title {
  color: rgb(63, 62, 62);
  margin-top: 12px;
  margin-bottom: 4px;
  font-size: 22px;
}

.offer_pending_box__subtitle {
  font-size: 12px;
}

.offer_pending_box__amount_wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #ccc;
  margin: 6px 0px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 6px;
}

.offer_pending_box__amount {
  font-size: 42px;
  width: 140px;
  font-style: bold;
  color: rgb(13, 92, 147);
}

.offer_pending_box__amount:hover {
  cursor: pointer;
  color: white;
}

.offer_pending_box__amount_wrapper:hover {
  color: white;
  background-color: rgb(59, 106, 238);
}

.offer_pending_box__list {
  margin-left: -20px;
}

.offer_pending_box__text {
  color: grey;
  font-size: 14px;
}

.offer_pending_box__button {
  background-color: rgb(188, 32, 40);
  color: white;
  border-radius: 20px;
  width: 140px;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  height: 40px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.offer_pending_box__button:hover {
  background-color: rgb(255, 0, 0);
  cursor: pointer;
}

.offer_pending_box__button:active {
  background-color: pink;
  box-shadow: none;
}

.offer_pending_box__button_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 300px;
}
