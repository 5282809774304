:root {
  --white: #ffffff;
  --yellow: #ffbf44;
  --black: #000;
  --blue: blue;
  --blue-faded: rgb(163, 190, 248);
}

.button_label {
  color: black;
  font-size: 14px;
  font-weight: 400;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  /* z-index: 2000; */
  margin-top: -4px;
}

.button_input {
  visibility: hidden;
}

.button_input:checked + span {
  border: 2px solid black;
  color: white;
}

.button_input:checked + span:after {
  opacity: 1;
}

.custom_button {
  left: -8px;
  top: 6px;
  cursor: pointer;
  width: 140px;
  height: 40px;
  border: 2px solid #000;
  border-radius: 20px;
  display: inline-block;
  position: relative;
  color: var(--black);
  text-align: center;
  vertical-align: middle;
  padding-top: 6px;
  z-index: 2000;
  margin: 10px 0px;
}

.custom_button:hover {
  background: var(--blue-faded);
  color: var(--white);
  border: 2px solid var(--yellow);
}

.custom_button:active {
  border: 2px solid var(--black);
}

/* for inner filled circle */
.custom_button::after {
  content: "";
  width: 140px;
  height: 40px;
  background-color: var(--blue);
  position: absolute;
  border-radius: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s;
  z-index: -1;
  border: 2px solid var(--black);
}
