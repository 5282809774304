.buy_now__button {
  background-color: rgb(188, 32, 40);
  color: white;
  border-radius: 20px;
  width: 140px;
  height: 40px;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}

.cta_button {
  background-color: rgb(188, 32, 40);
  color: white;
  border-radius: 20px;
  width: 500px;
  height: 40px;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}

.cta_button:hover {
  background-color: white;
  color: rgb(188, 32, 40);
  cursor: pointer;
  border: 1px solid rgb(188, 32, 40);
}

.cta_button:active {
  background-color: pink;
}
