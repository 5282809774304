.detail {
  display: flex;
  border-radius: 10px;
  margin: 120px 30px 30px 30px;
  margin-bottom: 30px;
}

.detail_page__left {
  display: flex;
  flex-direction: column;
  width: 66%;
  margin-right: 10px;
}

.detail_page__right {
  display: flex;
  flex-direction: column;
  width: 33%;
  margin-left: 0px 30px 0px 10px; /* TODO - fix this */
}
