.request_date__request_date_wrapper {
  display: flex;
  flex-direction: row;
  width: 480px;
  color: black;
  text-align: center;
  padding: 20px 20px 10px 20px;
  vertical-align: middle;
}

.request_date__photo_wrapper {
  width: 120px;
  height: 140px;
  margin: 6px 6px 0px 6px;
}

.request_date__photo {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.request_date__text {
  font-size: 28px;
  color: blue;
  height: 30px;
  vertical-align: middle;
  padding-top: 50px;
  padding-left: 30px;
}

.request_date__subtext {
  color: rgb(172, 170, 170);
  margin: 10px 0px 10px 0px;
  text-align: center;
}
