.prefs_panel__container {
  --transition-speed: 0.3s;
}

.prefs_panel {
  background: #fff;
  width: 340px;
  height: 100%;
  overflow: auto;
  position: fixed;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  transition: transform var(--transition-speed) ease;
  z-index: 1000;
}

.prefs_panel__backdrop {
  visibility: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity var(--transition-speed) ease,
    visibility var(--transition-speed) ease;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  z-index: 0;
}

/*
      Only apply the box-shadow when open to prevent the shadow
    from appearing on the edge of the screen when inactive
   */
.prefs_panel_container.open.prefs_panel {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.prefs_panel__backdrop.open {
  visibility: visible;
  opacity: 0.5;
  pointer-events: auto;
  z-index: 999;
  background-color: rgb(41, 42, 41);
}

.prefs_panel.right {
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.prefs_panel.open.right {
  transform: translateX(0);
}

.prefs_panel.top {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  transform: translateY(-100%);
  height: 40%;
}

.prefs_panel.bottom {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  transform: translateY(100%);
  height: 40%;
}

.prefs_panel.left {
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.prefs_panel.open.left {
  transform: translateX(0);
}

.prefs_panel_container.in.open.left,
.prefs_panel_container.in.open.right {
  transform: translateX(0);
}

.prefs_panel_container.in.open.top,
.prefs_panel_container.in.open.bottom {
  transform: translateY(0);
}
