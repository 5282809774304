.home {
  margin-top: 130px;
}

.home__title {
  font-size: 1.2rem;
  font-weight: 700;
  padding: 10px 0px 10px 30px;
  text-align: left;
}
