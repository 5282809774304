.interests {
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 10px 0px;
  min-height: 200px;
}

.interests__title {
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  margin: 20px 10px 0px 12px;
  padding-bottom: 10px;
}

.interests__grid {
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 10px;
}

.interests__item {
  background-color: blue;
  border-radius: 20px;
  color: white;
  padding: 10px;
  margin: 6px 6px;

  width: 140px;
  height: 40px;
  border: 2px solid #000;
  border-radius: 20px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  padding-top: 6px;
}

.interests__title_bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px rgb(172, 170, 170) solid;
  margin-left: 10px;
  margin-right: 10px;
  vertical-align: middle;
}

.interests__edit {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 4px 8px 6px 8px;
  margin-top: 6px;
  margin-right: 6px;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.2);
}

.interests__edit_hide {
  color: red;
  display: none;
}

.interests__edit:hover {
  cursor: pointer;
  color: #1d52e5;
  box-shadow: 2px 2px 4px #7f9cea;
}

.interests__edit:active {
  color: white;
  background-color: #99c0f6;
  box-shadow: 0px 0px 0px #1d52e5;
}

.interests__cancel {
  border: 1px solid #ccc;
  border-radius: 30px;
  padding: 4px 12px 6px 14px;
  margin-top: 2px;
  margin-right: 6px;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.2);
}

.interests__cancel:hover {
  cursor: pointer;
  color: #1d52e5;
  box-shadow: 2px 2px 4px #7f9cea;
}

.interests__cancel:active {
  color: white;
  background-color: #99c0f6;
  box-shadow: 0px 0px 0px #1d52e5;
}

.interests__save {
  background: blue;
  color: white;
  border-radius: 20px;
  width: 100px;
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  margin: 10px 10px 10px auto;
}

.interests__save:hover {
  cursor: pointer;
  background: red;
}

.interests__save:active {
  cursor: pointer;
  background: pink;
}
