.main_header {
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  overflow: hidden;
  top: 0px;
  background-color: white;
  z-index: 100;
  padding-right: 20px;
}

.nav_left {
  display: flex;
  margin-left: 10px;
  width: 760px;
  overflow: hidden;
  float: left;
}

.nav_right {
  width: 900px;
  overflow: hidden;
  display: flex;
  align-self: center;
  place-content: flex-end;
}

.nav_logo {
  width: 100px;
  height: 90px;
  padding: 16px 20px;
  float: left;
}

.nav_item {
  display: inline-block;
  padding: 12px 20px 10px 10px;
  color: black;
  font-weight: bold;
}

.nav_icon {
  color: blue;
  text-decoration: none !important;
}

.nav_link {
  background-color: white;
  text-decoration: none;
}

.nav_icon:hover {
  color: red;
}

.nav_icon:active {
  color: pink;
}

.nav_icon_label {
  /* TODO - Text is underlined!? */
  color: black;
  text-decoration: none;
  font-size: 12px;
  text-align: center;
  background-color: white;
}

.nav_list {
  padding-left: 30px;
  display: flex;
  align-items: center;
  padding-inline-start: 0px;
}

.user_name {
  display: inline-block;
  padding: 12px 20px 10px 10px;
  color: black;
  font-weight: bold;
  width: 900px;
}

.login {
  width: 140px;
  display: inline-block;
  padding: 12px 20px 10px 10px;
  color: black;
  font-weight: bold;
}
