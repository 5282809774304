.search {
  margin-top: 130px;
}

.grid {
  border: 1px rgb(172, 170, 170) solid;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 1300px;
  margin-left: 180px;
  margin-top: 30px;
  padding: 10px;
}
