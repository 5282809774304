.messages_list__items {
  width: 400px;
  height: 70vh;
  overflow: scroll;
}

.messages_list__text {
  font-weight: bold;
  color: rgb(47, 190, 238);
  font-size: 18px;
  padding: 10px 4px 10px 12px;
  text-align: left;
}
