.about_me {
  border: 1px solid #ccc;
  border-radius: 10px;
  z-index: 90;
}

.about_me__edit {
  align-items: left;
  z-index: 90;
}

.about_me__title {
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  margin: 20px 10px 0px 6px;
  padding-bottom: 10px;
}

.about_me__details {
  font-size: 16px;
  padding: 10px 20px 4px 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.about_me__details_edit {
  font-size: 16px;
  padding: 10px 20px 4px 20px;
  display: flex;
  justify-content: left;
  flex-direction: column;
  text-align: left;
}

.about_me__dl {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  color: rgba(0, 0, 0, 0.44);
}

.about_me__dd {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.44);
  text-align: left;
}

.about_me__dt {
  font-size: 16px;
  text-align: right;
  color: rgba(0, 0, 0, 0.65);
}

.about_me__title_bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px rgb(172, 170, 170) solid;
  margin-left: 10px;
  margin-right: 10px;
  vertical-align: middle;
}

.about_me__cancel {
  border: 1px solid #ccc;
  border-radius: 30px;
  padding: 4px 12px 6px 14px;
  margin-top: 2px;
  margin-right: 6px;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.2);
}

.about_me__cancel:hover {
  cursor: pointer;
  color: #1d52e5;
  box-shadow: 2px 2px 4px #7f9cea;
}

.about_me__cancel:active {
  color: white;
  background-color: #99c0f6;
  box-shadow: 0px 0px 0px #1d52e5;
}

.about_me__save {
  background: blue;
  color: white;
  border-radius: 20px;
  width: 100px;
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  margin: 10px 10px 10px auto;
}

.about_me__save:hover {
  cursor: pointer;
  background: red;
}

.about_me__save:active {
  cursor: pointer;
  background: pink;
}

.about_me__occupation_edit {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 0px;
}
