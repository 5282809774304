@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");

body {
  margin: 0;
  font-family: system-ui, -apple-system, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Dropdown-arrow {
  margin-top: 4px;
}

.Dropdown-menu {
  z-index: 9999 !important;
}

.modal-content {
  border: none !important;
  min-height: none;
}

.primary-button {
  background-color: rgb(188, 32, 40);
  color: white;
  border-radius: 20px;
  width: 140px;
  height: 40px;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}

.secondary-button {
  background-color: white;
  color: black;
  border-radius: 20px;
  width: 140px;
  height: 40px;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}
