.auth {
  margin: 8rem auto;
  width: 95%;
  max-width: 25rem;
  border-radius: 6px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  text-align: center;
  color: black;
}

.auth h1 {
  text-align: center;
  color: black;
}

.auth__form_item {
  margin-bottom: 0.5rem;
}

.auth__form_item label {
  display: block;
  color: black;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.auth__form_item input {
  font: inherit;
  background-color: #e7ecf2;
  color: #38015c;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  text-align: left;
  padding: 0.25rem;
}

.actions {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.actions button {
  cursor: pointer;
  font: inherit;
  color: white;
  background-color: #3844eb;
  border: 1px solid #5660e8;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
  width: 220px;
}

.actions button:hover {
  background-color: #5b64eb;
  border-color: #5f68e4;
}

.actions .toggle {
  margin-top: 1rem;
  background-color: transparent;
  color: grey;
  border: none;
  padding: 0.15rem 1.5rem;
}

.actions .toggle:hover {
  background-color: transparent;
  color: grey;
}

.acct_part_1__dob {
  display: flex;
  flex-direction: row;
  padding: 20px 20px 20px 10px;
}

.modal {
  background: white;
  border-radius: 10px;
  --bs-modal-width: 600px;
  min-height: 0px !important;
}

.modal_header {
  background-color: blue;
  color: white;
}
