.messages_dialog {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  height: 60vh;
  width: 100%;
  overflow-y: auto;
  padding: 20px 6px 0px 6px;
}

.messages_dialog__row_left,
.messages_dialog__row_right,
.messages_dialog__row_center {
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
}

.messages_dialog__row_left {
  justify-content: left;
}

.messages_dialog__row_right {
  justify-content: right;
}

.messages_dialog__row_center {
  justify-content: center;
}

.messages_dialog_bubble_left,
.messages_dialog_bubble_right {
  color: white;
  border-radius: 10px;
  padding: 14px;
  margin: 6px;
  width: auto;
}

.messages_dialog_bubble_left {
  text-align: left;
  padding-left: 8px;
  margin-left: 10px;
  background-color: #acaaaa;
}

.messages_dialog_bubble_right {
  text-align: right;
  padding-right: 8px;
  margin-right: 10px;
  background-color: blue;
}
