.detail_box {
  display: flex;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  max-height: 372px;
}

.detail_box__header {
  border-bottom: 2px rgb(172, 170, 170) solid;
}

.detail_box__gallery {
  display: flex;
}

.detail_box__image {
  background-position: "center";
  background-size: "cover";
  background-repeat: "no-repeat";
  height: 100%;
  width: 240px;
  float: left;
  border-radius: 10px;
}

.detail_box__name_bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  width: 100%;
}

.detail_box__name_bar {
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  margin-top: 10px;
  /* margin-left: 250px; */
}

.detail_box__subtext {
  font-size: 14px;
  text-align: left;

  /* margin-top: 6px; */
  /* margin-left: 250px; */
}

.detail_box__right {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 15px;
  text-align: start;
}

.detail_box__cancel {
  border: 1px solid #ccc;
  border-radius: 30px;
  padding: 4px 12px 6px 14px;
  margin-top: 2px;
  margin-right: 6px;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.2);
  font-weight: normal;
  font-size: 14px;
}

.detail_box__cancel:hover {
  cursor: pointer;
  color: #1d52e5;
  box-shadow: 2px 2px 4px #7f9cea;
}

.detail_box__cancel:active {
  color: white;
  background-color: #99c0f6;
  box-shadow: 0px 0px 0px #1d52e5;
}

.detail_box__save {
  background: blue;
  color: white;
  border-radius: 20px;
  width: 100px;
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  margin: 10px 10px 10px auto;
}

.detail_box__save:hover {
  cursor: pointer;
  background: red;
}

.detail_box__save:active {
  cursor: pointer;
  background: pink;
}

.detail_box__form_item {
  margin-bottom: 0.5rem;
}

.detail_box__form_item label {
  display: block;
  color: black;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.detail_box__form_item input {
  font: inherit;
  background-color: #e7ecf2;
  color: #38015c;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  text-align: left;
  padding: 0.25rem;
}

.detail_box__offer_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 460px;
  justify-content: center;
  background-color: lightblue;
  border-radius: 10px;
  padding: 0px 10px 10px 10px;
}

.detail_box__right_top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
