.favorite_button__wrapper {
  margin: 0px 10px 0px 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70px;
}

.favorite_button__label,
.favorite_button__label_unfavorite {
  font-size: 14px;
}

.favorite_button__label_unfavorite {
  color: red;
}

.favorite_button__icon,
.favorite_button__icon_unfavorite {
  border-radius: 30px;
  border-color: red;
  border-width: 1px;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.2);
  width: 50px;
  height: 40px;
  vertical-align: middle;
  padding-top: 6px;
}

.favorite_button__icon_unfavorite {
  color: red;
}

.favorite_button__icon:hover {
  color: red;
}

.favorite_button__icon_unfavorite:hover {
  color: black;
}

.favorite_button__icon_unfavorite:active {
  color: grey;
}

.favorite_button__icon:active {
  color: pink;
  box-shadow: none;
}
