.edit_button {
  border: 1px solid #ccc;
  border-radius: 30px;
  padding: 4px 12px 6px 16px;
  margin-top: 2px;
  margin-right: 6px;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  font-weight: normal;
}

.edit_button__edit {
  color: white;
  background-color: #99c0f6;
  box-shadow: 4px 4px 6px #1d52e5;
}

.edit_button:hover {
  cursor: pointer;
  color: #1d52e5;
  box-shadow: 2px 2px 4px #7f9cea;
}

.edit_button:active {
  color: white;
  background-color: #99c0f6;
  box-shadow: 0px 0px 0px #1d52e5;
}
