.add_photo {
  background-color: #fff;
  border: 2px solid blue;
  color: blue;
  font-style: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 160px;
  border-radius: 10px;
  margin: 0px 10px 10px 10px;
  height: 200px;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.add_photo__icon {
  color: blue;
}

.add_photo__wrapper:hover {
  cursor: pointer;
  color: red;
}

.add_photo__wrapper:active {
  cursor: pointer;
  color: pink;
}
