.offer_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 460px;
  justify-content: center;
  background-color: lightblue;
  border-radius: 10px;
  padding: 0px 10px 10px 10px;
}

.offer_box__title {
  color: rgb(63, 62, 62);
  margin-top: 12px;
  margin-bottom: 4px;
}

.offer_box__input {
  /* margin-top: 12px; */
  font-size: 36px;
  width: 300px;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #ccc;
  margin: 6px 0px;
  color: rgb(0, 0, 255);
}

.offer_box__list {
  margin-left: -20px;
  /* margin-bottom: 4px;
  margin-top: 8px; */
}

.offer_box__list_item {
  display: inline-block;
  color: black;
  border-radius: 10px;
  padding: 4px;
  margin: 4px;
  border: 1px solid white;
  background-color: white;
}

.offer_box__list_item:hover {
  background-color: rgb(0, 0, 255);
  color: white;
  cursor: pointer;
  border: 1px solid rgb(0, 0, 255);
}

.offer_box__text {
  color: grey;
  font-size: 14px;
}

.offer_box__button {
  background-color: rgb(188, 32, 40);
  color: white;
  border-radius: 20px;
  width: 140px;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  height: 40px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.offer_box__button:hover {
  background-color: rgb(255, 0, 0);
  cursor: pointer;
}

.offer_box__button:active {
  background-color: pink;
  box-shadow: none;
}
