.card_offer_box__wrapper {
  width: 100px;
  border: 1px solid #ccc;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.card_offer_box__label {
  font-size: 12px;
  color: #737373;
}

.card_offer_box__amount {
  font-size: 32px;
  font-weight: bold;
  color: rgb(67, 85, 241);
  height: 38px;
}
