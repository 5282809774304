.prefs_main__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.prefs_main__avatar_frame {
  width: 160px;
  height: 160px;
  margin-left: auto;
  margin-right: auto;
}

.prefs_main__avatar {
  border-radius: 80px;
  width: 100%;
  height: 100%;
}

.prefs_main__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 14px 20px;
  text-decoration: none;
  font-style: bold;
}

.prefs_main__item:hover {
  cursor: pointer;
}

.prefs_main__link {
  background-color: white;
  text-decoration: none;
  margin-top: 4px;
  color: rgb(82, 81, 81);
}

.prefs_main__link:hover {
  color: blue;
}

.prefs_main__item_right {
  margin-left: 86%;
}

.prefs_main__screen_name {
  font-size: 24px;
  font-weight: bold;
  color: #424ad9;
  margin-left: auto;
  margin-right: auto;
}

.prefs_main__edit_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.prefs_main__edit_profile {
  border: 1px solid #424ad9;
  font-size: 14px;
  color: #424ad9;
  border-radius: 30px;
  padding: 0px 8px 1px 4px;
  margin: 0px auto 20px auto;
  width: 70px;
}

.prefs_main__edit_profile:hover {
  background-color: #424ad9;
  color: white;
  cursor: pointer;
}

.prefs_main__edit_profile:active {
  background-color: #8388d6;
  color: white;
  cursor: pointer;
}
