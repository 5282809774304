.interests_tabs {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.interests_tabs__item {
  text-align: center;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65);
}

.interests_tabs__item_selected {
  border-bottom: 2px solid red;
  color: red;
}

.interests_tabs__item:hover {
  color: red;
}

.interests_tabs__item:active {
  color: pink;
}
