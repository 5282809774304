.cancel_button__wrapper {
  margin: 0px 10px 0px 10px;
}

.cancel_button__icon {
  border-radius: 30px;
  border-color: red;
  border-width: 1px;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.2);
  width: 50px;
  height: 40px;
  vertical-align: middle;
  padding-top: 6px;
}

.cancel_button__icon:hover {
  color: red;
}

.cancel_button__icon:active {
  color: pink;
  box-shadow: none;
}

.cancel_button__label {
  font-size: 14px;
}
