.messages_top__message_top {
  display: flex;
  flex-direction: row;
  height: 140px;
  border: 1px solid #ccc;
  position: relative;
  padding: 10px;
  padding-left: 20px;
  align-items: center;
}

.messages_top__message_avatar {
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.messages_top__connection_info {
  text-align: left;
  padding-left: 10px;
  top: 0px;
  position: relative;
  vertical-align: text-top;
}

.messages_top__accepted {
  font-weight: bold;
  color: rgb(47, 190, 238);
  font-size: 18px;
  padding-top: 4px;
}
