.grid {
  border: 1px rgb(172, 170, 170) solid;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-left: 180px;
  margin-top: 30px;
}

.grid__text {
  font-size: 22px;
  color: rgb(172, 170, 170);
  text-align: left;
  vertical-align: middle;
  border-bottom: 1px rgb(172, 170, 170) solid;
  margin: 40px 40px 0px 40px;
  padding-bottom: 6px;
  padding-left: 10px;
  height: 40px;
}

.grid__cards {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
}
