.messages_page {
  margin-top: 130px;
  display: flex;
  border-radius: 10px;
  margin: 120px 30px 30px 30px;
  margin-bottom: 30px;
}

.messages_page__left {
  display: flex;
  flex-direction: column;
  width: 400px;
  border: 1px solid #ccc;
  border-radius: 10px 0px 0px 10px;
  height: 100%;
}

.messages_page__right {
  display: flex;
  flex-direction: column;
  margin: 0px 10px 0px 0px;
  border: 1px solid #ccc;
  border-radius: 0px 10px 10px 0px;
  width: 100%;
}
