.offer_sent__wrapper {
  display: flex;
  flex-direction: column;
  width: 480px;
  color: black;
  text-align: center;
  vertical-align: middle;
  align-items: center;
}

.offer_sent__top {
       display: flex;
  flex-direction: row;
  width: 100%;
  color: blue;
  text-align: center;
  padding: 20px 20px 10px 20px;
  vertical-align: middle;
}
.offer_sent__amount {
  color: blue;
  font-size: 36px;
  width: 100%;
  margin-top: 50px;
}

.offer_sent__label {
  width: 100%;
  text-align: center;
  padding: 10px;
}

.offer_sent__photo_wrapper {
  width: 120px;
  height: 140px;
  margin: 6px 6px 0px 6px;
}

.offer_sent__photo {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.offer_sent__text {
  font-size: 28px;
  color: blue;
  height: 30px;
  vertical-align: middle;
  padding-top: 50px;
  padding-left: 30px;
}

.offer_sent__subtext {
  color: rgb(172, 170, 170);
  margin: 10px 0px 10px 0px;
  text-align: center;
}

.offer_sent__close_button {
  background-color: rgb(188, 32, 40);
  color: white;
  border-radius: 20px;
  width: 140px;
  height: 40px;
  margin-bottom: 14px;
}

.offer_sent__close_button:hover {
  background-color: rgb(255, 0, 0);
  cursor: pointer;
}

.offer_sent__close_button:active {
  background-color: pink;
  box-shadow: none;
}
