.user_widget {
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 180px;
  color: black;
  vertical-align: middle;
  position: fixed;
  top: 30px;
  right: 0;
}

.user_widget__text {
  text-align: left;
  font-size: 14px;
  vertical-align: middle;
  padding: 4px;
  color: rgba(0, 0, 0, 0.65);
}

.user_widget__avatar_wrapper {
  width: 44px;
  height: 50px;
  background-color: white;
  padding-right: 4px;
}

.user_widget__avatar {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
